<template>
  <ol v-if="state" class="vgs-infinite-hits-list">
    <li
      v-for="hit in state.hits"
      :key="hit.objectID"
      class="vgs-infinite-hits-list-item"
    >
      <slot name="item" :item="hit"> </slot>
    </li>
    <li
      style="list-style-type: none"
      v-observe-visibility="visibilityChanged"
    />
  </ol>
</template>

<script>
import { createWidgetMixin } from "vue-instantsearch";
import { connectInfiniteHits } from "instantsearch.js/es/connectors";
export default {
  name: "InfiniteHits",
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    }
  }
};
</script>
