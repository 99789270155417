<template>
  <div :id="id">
    <home :id="id" />
  </div>
</template>

<script>
import Home from "@/components/Home.vue";

export default {
  name: "App",
  props: {
    id: String
  },
  components: {
    home: Home
  }
};
</script>
