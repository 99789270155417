<template>
  <div
    :class="{
      'vgs-facet-selector': true,
      'vgs-facet-selector--hidden': facet.hidden
    }"
  >
    <ais-refinement-list
      :attribute="attribute"
      :transformItems="items => filterItems(items, facet.path)"
      :limit="200"
      :sort-by="['isRefined', 'name:asc']"
      :operator="'and'"
    >
      <div class="vgs-facet-selector-component" slot-scope="{ items, refine }">
        <div class="vgs-facet-selector__header" v-if="items.length > 0">
          {{ facet.header }}
        </div>
        <ul class="vgs-facet-selector-component__list">
          <li
            v-show="(collapsed && index < 10) || !collapsed"
            class="vgs-facet-selector-component__item"
            v-for="(item, index) in items"
            :key="item.value"
          >
            <a
              :class="{
                'vgs-facet-selector-component__link': true,
                'vgs-facet-selector-component__link--active': item.isRefined
              }"
              @click="refine(item.value)"
              nde
            >
              <input
                class="vgs-facet-selector-component__checkbox"
                type="checkbox"
                v-model="item.isRefined"
              />
              <span class="vgs-facet-selector-component__label">{{
                label(item.label)
              }}</span>
              <span class="vgs-facet-selector-component__count">{{
                item.count.toLocaleString()
              }}</span>
            </a>
          </li>
        </ul>
        <a
          v-if="items.length > showMoreLimit"
          class="vgs-facet-selector-component__show-more"
          href=""
          @click.prevent="collapsed = !collapsed"
          >{{ currentLabel }}</a
        >
      </div>
    </ais-refinement-list>
  </div>
</template>

<script>
export default {
  name: "FacetSelector",
  props: {
    facet: Object,
    showMoreLabel: String,
    showLessLabel: String,
    showMoreLimit: Number
  },
  data() {
    return {
      collapsed: true
    };
  },
  methods: {
    filterItems(items) {
      // Is facet based on a single attribute or a hierarchical path
      if (this.facet.attribute) {
        // Filter based on hidden labels array in config obj
        return items.filter(item => {
          if (this.facet.hiddenLabels) {
            return this.facet.hiddenLabels.indexOf(item.label) === -1;
          }
          return item;
        });
      } else if (this.facet.path) {
        // Filter based on hidden labels array in config obj and if the path of the item matches the path of the facet
        return items
          .filter(item => {
            if (this.facet.hiddenLabels) {
              const label = item.label.split(" > ").pop();
              return (
                this.facet.hiddenLabels.indexOf(label) === -1 &&
                item.label.indexOf(this.facet.path) === 0
              );
            }
            return item.label.indexOf(this.facet.path) === 0;
          })
          .map(item => {
            const splitLabel = item.label.split(" > ");
            item.label = splitLabel.pop();
            return item;
          });
      }
      return [];
    },
    label(text) {
      // Return label from dictionary of facet, if exists.
      let label = this.facet.labels && this.facet.labels[text];
      return label || text;
    }
  },
  computed: {
    attribute() {
      // Return attribute or path
      return this.facet.attribute
        ? this.facet.attribute
        : "taxonomies_hierarchical.global_tags.lvl" + this.level;
    },
    level() {
      return this.facet.attribute
        ? null
        : // Count the number of > and add 1 if facet is not a root facet
          (this.facet.path.match(/>/g) || []).length +
            (this.facet.root ? 0 : 1);
    },
    currentLabel() {
      return this.collapsed ? this.showMoreLabel : this.showLessLabel;
    }
  }
};
</script>
