<template>
  <div class="vgs-home">
    <ais-instant-search
      v-if="facets.length > 0"
      :search-client="searchClient"
      :index-name="indexName"
      :routing="routing"
    >
      <div class="vgs-cols">
        <!-- Filter column -->
        <div
          :class="{
            'vgs-col--left': true,
            'vgs-col--expanded': filterExpanded
          }"
        >
          <!-- Filter toggle button -->
          <a class="vgs-close-filter-btn" @click="onFilterToggle"></a>

          <!-- Search stats -->
          <ais-stats>
            <div slot-scope="{ nbHits }">
              <span class="vgs-results-num__prefix">{{
                texts.resultsNumPrefix
              }}</span>
              <span class="vgs-results-num__value">{{ nbHits }}</span>
              <span class="vgs-results-num__postfix">{{
                texts.resultsNumPostfix
              }}</span>
            </div>
          </ais-stats>

          <!-- Facet selectors -->
          <facet-selector
            v-for="facet in facets"
            :key="`facet-${facet.attribute || facet.path}`"
            :facet="facet"
            :show-less-label="texts.showLess"
            :show-more-label="texts.showMore"
            :show-more-limit="showMoreLimit"
          />
        </div>
        <div class="vgs-col--main">
          <a class="vgs-toggle-filter-btn" @click="onFilterToggle">
            {{ filterExpanded ? texts.filterClose : texts.filterOpen }}
          </a>

          <!-- Search input -->
          <ais-search-box>
            <div slot-scope="{ isSearchStalled, refine }">
              <form
                role="search"
                novalidate="novalidate"
                class="ais-SearchBox-form"
                v-on:submit.prevent
              >
                <input
                  type="search"
                  v-model="searchFieldValue"
                  autocapitalize="off"
                  autocomplete="off"
                  spellcheck="false"
                  required="required"
                  maxlength="512"
                  aria-label="Search"
                  class="ais-SearchBox-input"
                  :placeholder="texts.searchPlaceholder"
                  @input="
                    event => {
                      const value = event.currentTarget.value;
                      if (value.length > 1 || value.length === 0) {
                        refine(value);
                      }
                    }
                  "
                />
              </form>
              <span :hidden="!isSearchStalled">Loading...</span>
            </div>
          </ais-search-box>

          <!-- Share button -->
          <ais-state-results>
            <div slot-scope="{ _rawResults }">
              <share-button
                :label="texts.share"
                :label-confirm="texts.shareConfirm"
                @share-button-click="copyUrl(_rawResults[0].params)"
              />
            </div>
          </ais-state-results>

          <!-- List of current refinements -->
          <ais-current-refinements
            :transformItems="items => formatRefinementHeader(items)"
          />

          <!-- Clear refinements -->
          <ais-clear-refinements>
            <a
              slot-scope="{ canRefine, refine, createURL }"
              :href="createURL()"
              @click.prevent="refine"
              v-if="canRefine"
            >
              {{ texts.clearRefinements }}
            </a>
          </ais-clear-refinements>

          <!-- Config -->
          <ais-configure :hits-per-page.camel="hitsPerPage" />

          <!-- Infinite hits -->
          <infinite-hits>
            <template slot="item" slot-scope="{ item }">
              <result-item :item="item" />
            </template>
          </infinite-hits>
        </div>
      </div>
    </ais-instant-search>

    <!-- Overlay link -->
    <a
      v-if="id === 'vg-site-search-overlay'"
      class="vgs-close-btn"
      href="#"
      @click.prevent="onCloseClick()"
      >{{ texts.close }}</a
    >
  </div>
</template>

<script>
import queryString from "query-string";
import algoliasearch from "algoliasearch/lite";
import { history as historyRouter } from "instantsearch.js/es/lib/routers";
import InfiniteHits from "@/components/InfiniteHits.vue";
import FacetSelector from "@/components/FacetSelector.vue";
import ResultItem from "@/components/ResultItem.vue";
import ShareButton from "@/components/ShareButton.vue";
export default {
  name: "Home",
  props: {
    id: String
  },
  data() {
    return {
      searchClient: null,
      indexName: "",
      facets: [],
      texts: {},
      routing: null,
      filterExpanded: false,
      searchPageUrl: "",
      searchFieldValue: ""
    };
  },
  mounted() {
    const settings = window[this.id];
    this.searchClient = algoliasearch(settings.appId, settings.apiKey);
    this.indexName = settings.indexName;
    this.facets = settings.facets;
    this.hitsPerPage = settings.hitsPerPage;
    this.showMoreLimit = settings.showMoreLimit;
    this.searchPageUrl = settings.searchPageUrl;
    this.texts = settings.texts;

    if (this.isOverlay) {
      document
        .getElementById("menu-item-search")
        .addEventListener("click", this.onOpenClick);
    }

    if (!this.isOverlay) {
      this.routing = {
        router: historyRouter(),
        stateMapping: {
          stateToRoute(uiState) {
            return {
              query: uiState.query,
              posttype:
                uiState.refinementList &&
                uiState.refinementList.post_type_label,
              lvl0:
                uiState.refinementList &&
                uiState.refinementList[
                  "taxonomies_hierarchical.global_tags.lvl0"
                ] &&
                uiState.refinementList[
                  "taxonomies_hierarchical.global_tags.lvl0"
                ].join("~"),
              lvl1:
                uiState.refinementList &&
                uiState.refinementList[
                  "taxonomies_hierarchical.global_tags.lvl1"
                ] &&
                uiState.refinementList[
                  "taxonomies_hierarchical.global_tags.lvl1"
                ].join("~"),
              lvl2:
                uiState.refinementList &&
                uiState.refinementList[
                  "taxonomies_hierarchical.global_tags.lvl2"
                ] &&
                uiState.refinementList[
                  "taxonomies_hierarchical.global_tags.lvl2"
                ].join("~"),
              page: uiState.page
            };
          },
          routeToState(routeState) {
            return {
              query: routeState.query,
              refinementList: {
                post_type_label: routeState.posttype,
                "taxonomies_hierarchical.global_tags.lvl0":
                  routeState.lvl0 && routeState.lvl0.split("~"),
                "taxonomies_hierarchical.global_tags.lvl1":
                  routeState.lvl1 && routeState.lvl1.split("~"),
                "taxonomies_hierarchical.global_tags.lvl2":
                  routeState.lvl2 && routeState.lvl2.split("~")
              },
              page: routeState.page
            };
          }
        }
      };
    }
  },
  components: {
    facetSelector: FacetSelector,
    resultItem: ResultItem,
    infiniteHits: InfiniteHits,
    shareButton: ShareButton
  },
  methods: {
    formatRefinementHeader(items) {
      items.forEach(item => {
        item.refinements.forEach(refinement => {
          const splitLabel = refinement.label.split(" > ");
          refinement.label = splitLabel[splitLabel.length - 1];
        });
      });
      return items;
    },
    onOpenClick(e) {
      e.preventDefault();
      document.getElementsByTagName("HTML")[0].style.overflowY = "hidden";
      this.addClass(document.getElementById(this.id), this.id + "--visible");
      document.querySelector(`#${this.id} .ais-SearchBox-input`).focus();
    },
    onCloseClick() {
      document.getElementsByTagName("HTML")[0].style.overflowY = "auto";
      this.removeClass(document.getElementById(this.id), this.id + "--visible");
    },
    addClass(el, className) {
      if (el.classList) el.classList.add(className);
      else el.className += " " + className;
    },
    onFilterToggle() {
      this.filterExpanded = !this.filterExpanded;
    },
    removeClass(el, className) {
      if (el.classList) el.classList.remove(className);
      else
        el.className = el.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    copyUrl(params) {
      const qs = queryString.parse(params);

      const facets = qs.facetFilters ? JSON.parse(qs.facetFilters) : [];
      const query = qs.query || "";
      const url = this.generateUrl(facets, query);
      this.$copyText(url);
    },
    generateUrl(facets, query) {
      const queryObj = {};
      facets.forEach(filter => {
        // Parse the filters
        const split = filter.split(":");

        const key = split[0];
        const splitKey = key.split(".");
        const formattedKey = splitKey[splitKey.length - 1];

        const value = split[1];

        if (queryObj[formattedKey]) {
          queryObj[formattedKey].push(value);
        } else {
          queryObj[formattedKey] = [value];
        }
      });

      // Convert value arrays to strings
      Object.keys(queryObj).forEach(key => {
        queryObj[key] = queryObj[key].join("~");
      });

      // Add query if it's not empty
      if (query.length > 0) {
        queryObj.query = query;
      }

      return this.searchPageUrl + "?" + queryString.stringify(queryObj);
    }
  },
  computed: {
    isOverlay() {
      return this.id === "vg-site-search-overlay";
    }
  }
};
</script>
<style></style>
