var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vgs-home"},[(_vm.facets.length > 0)?_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.indexName,"routing":_vm.routing}},[_c('div',{staticClass:"vgs-cols"},[_c('div',{class:{
          'vgs-col--left': true,
          'vgs-col--expanded': _vm.filterExpanded
        }},[_c('a',{staticClass:"vgs-close-filter-btn",on:{"click":_vm.onFilterToggle}}),_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var nbHits = ref.nbHits;
return _c('div',{},[_c('span',{staticClass:"vgs-results-num__prefix"},[_vm._v(_vm._s(_vm.texts.resultsNumPrefix))]),_c('span',{staticClass:"vgs-results-num__value"},[_vm._v(_vm._s(nbHits))]),_c('span',{staticClass:"vgs-results-num__postfix"},[_vm._v(_vm._s(_vm.texts.resultsNumPostfix))])])}}],null,false,3274104681)}),_vm._l((_vm.facets),function(facet){return _c('facet-selector',{key:("facet-" + (facet.attribute || facet.path)),attrs:{"facet":facet,"show-less-label":_vm.texts.showLess,"show-more-label":_vm.texts.showMore,"show-more-limit":_vm.showMoreLimit}})})],2),_c('div',{staticClass:"vgs-col--main"},[_c('a',{staticClass:"vgs-toggle-filter-btn",on:{"click":_vm.onFilterToggle}},[_vm._v("\n          "+_vm._s(_vm.filterExpanded ? _vm.texts.filterClose : _vm.texts.filterOpen)+"\n        ")]),_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isSearchStalled = ref.isSearchStalled;
        var refine = ref.refine;
return _c('div',{},[_c('form',{staticClass:"ais-SearchBox-form",attrs:{"role":"search","novalidate":"novalidate"},on:{"submit":function($event){$event.preventDefault();}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFieldValue),expression:"searchFieldValue"}],staticClass:"ais-SearchBox-input",attrs:{"type":"search","autocapitalize":"off","autocomplete":"off","spellcheck":"false","required":"required","maxlength":"512","aria-label":"Search","placeholder":_vm.texts.searchPlaceholder},domProps:{"value":(_vm.searchFieldValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchFieldValue=$event.target.value},function (event) {
                    var value = event.currentTarget.value;
                    if (value.length > 1 || value.length === 0) {
                      refine(value);
                    }
                  }]}})]),_c('span',{attrs:{"hidden":!isSearchStalled}},[_vm._v("Loading...")])])}}],null,false,1455192203)}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var _rawResults = ref._rawResults;
return _c('div',{},[_c('share-button',{attrs:{"label":_vm.texts.share,"label-confirm":_vm.texts.shareConfirm},on:{"share-button-click":function($event){return _vm.copyUrl(_rawResults[0].params)}}})],1)}}],null,false,1646994151)}),_c('ais-current-refinements',{attrs:{"transformItems":function (items) { return _vm.formatRefinementHeader(items); }}}),_c('ais-clear-refinements',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var canRefine = ref.canRefine;
                  var refine = ref.refine;
                  var createURL = ref.createURL;
return (canRefine)?_c('a',{attrs:{"href":createURL()},on:{"click":function($event){$event.preventDefault();return refine($event)}}},[_vm._v("\n            "+_vm._s(_vm.texts.clearRefinements)+"\n          ")]):_vm._e()}}],null,true)}),_c('ais-configure',{attrs:{"hitsPerPage":_vm.hitsPerPage}}),_c('infinite-hits',{scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('result-item',{attrs:{"item":item}})]}}],null,false,3077199519)})],1)])]):_vm._e(),(_vm.id === 'vg-site-search-overlay')?_c('a',{staticClass:"vgs-close-btn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onCloseClick()}}},[_vm._v(_vm._s(_vm.texts.close))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }