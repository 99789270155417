<template>
  <a
    :class="{ 'vgs-share-button': true, 'vgs-share-button--confirm': !isReady }"
    @click="onClick"
    >{{ buttonLabel }}
  </a>
</template>

<script>
export default {
  name: "ShareButton",
  props: {
    label: String,
    labelConfirm: String
  },
  data() {
    return {
      isReady: true
    };
  },
  methods: {
    onClick() {
      if (this.isReady) {
        this.isReady = false;
        this.$emit("share-button-click");
        setTimeout(() => (this.isReady = true), 2000);
      }
    }
  },
  computed: {
    buttonLabel() {
      return this.isReady ? this.label : this.labelConfirm;
    }
  }
};
</script>
