<template>
  <div :class="classes">
    <a
      class="vgs-result-item__link vg-click"
      :href="item.permalink"
      style="text-decoration: none; color: black"
    >
      <div
        class="vgs-result-item__thumbnail"
        :style="{
          backgroundImage: `url(${item.images &&
            item.images.large &&
            item.images.large.url})`
        }"
      ></div>
      <div class="vgs-result-item__header">{{ item.post_title }}</div>
      <div class="vgs-result-item__excerpt">{{ excerpt }}</div>
      <div class="vgs-result-item__posttype">
        {{ item.post_type_label }}
      </div>
    </a>
  </div>
</template>

<script>
import h2p from "html2plaintext";
export default {
  name: "ResultItem",
  props: {
    item: Object
  },
  computed: {
    classes() {
      return [
        "vgs-result-item",
        "vgs-result-item--" +
          this.item.post_type_label.toLowerCase().replace(" ", "-")
      ];
    },
    excerpt() {
      if (this.item.content) {
        const title = this.item.post_title;
        // Get post excerpt and strip html, if excerpt is available
        // Else use post content and remove line breaks.
        let excerpt =
          this.item.post_excerpt.length > 0
            ? this.htmlToText(this.item.post_excerpt).trim()
            : this.item.content.replace(/(?:\r\n|\r|\n)/g, " ").trim();
        excerpt = excerpt.replace("  ", " ");

        // Trim away header if present in start of content
        // Posts with post_type "tribe_event" and "providers" are excluded from this rule
        const headerIndex = excerpt.toLowerCase().indexOf(title.toLowerCase());
        if (
          headerIndex === 0 &&
          (this.item.post_type !== "tribe_events" &&
            this.item.post_type !== "providers")
        ) {
          excerpt = excerpt.substring(title.length);
        }

        if (excerpt.length <= 140) {
          return excerpt;
        }
        return excerpt.substr(0, excerpt.lastIndexOf(" ", 140)) + " …";
      }
      return "";
    }
  },
  methods: {
    htmlToText(input) {
      return h2p(input);
    }
  }
};
</script>
