import Vue from "vue";
import App from "./App.vue";
import InstantSearch from "vue-instantsearch";
import VueObserveVisibility from "vue-observe-visibility";
import VueClipboard from "vue-clipboard2";

Vue.config.productionTip = false;

Vue.use(InstantSearch);
Vue.use(VueObserveVisibility);
Vue.use(VueClipboard);

const siteSearch = document.getElementById("vg-site-search");
const siteSearchOverlay = document.getElementById("vg-site-search-overlay");

if (siteSearch) {
  new Vue({
    render: h =>
      h(App, {
        props: {
          id: siteSearch.id
        }
      })
  }).$mount(siteSearch);
}

if (siteSearchOverlay) {
  new Vue({
    render: h =>
      h(App, {
        props: {
          id: siteSearchOverlay.id
        }
      })
  }).$mount(siteSearchOverlay);
}
