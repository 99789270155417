var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'vgs-facet-selector': true,
    'vgs-facet-selector--hidden': _vm.facet.hidden
  }},[_c('ais-refinement-list',{attrs:{"attribute":_vm.attribute,"transformItems":function (items) { return _vm.filterItems(items, _vm.facet.path); },"limit":200,"sort-by":['isRefined', 'name:asc'],"operator":'and'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var items = ref.items;
  var refine = ref.refine;
return _c('div',{staticClass:"vgs-facet-selector-component"},[(items.length > 0)?_c('div',{staticClass:"vgs-facet-selector__header"},[_vm._v("\n        "+_vm._s(_vm.facet.header)+"\n      ")]):_vm._e(),_c('ul',{staticClass:"vgs-facet-selector-component__list"},_vm._l((items),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:((_vm.collapsed && index < 10) || !_vm.collapsed),expression:"(collapsed && index < 10) || !collapsed"}],key:item.value,staticClass:"vgs-facet-selector-component__item"},[_c('a',{class:{
              'vgs-facet-selector-component__link': true,
              'vgs-facet-selector-component__link--active': item.isRefined
            },attrs:{"nde":""},on:{"click":function($event){return refine(item.value)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.isRefined),expression:"item.isRefined"}],staticClass:"vgs-facet-selector-component__checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.isRefined)?_vm._i(item.isRefined,null)>-1:(item.isRefined)},on:{"change":function($event){var $$a=item.isRefined,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "isRefined", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "isRefined", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "isRefined", $$c)}}}}),_c('span',{staticClass:"vgs-facet-selector-component__label"},[_vm._v(_vm._s(_vm.label(item.label)))]),_c('span',{staticClass:"vgs-facet-selector-component__count"},[_vm._v(_vm._s(item.count.toLocaleString()))])])])}),0),(items.length > _vm.showMoreLimit)?_c('a',{staticClass:"vgs-facet-selector-component__show-more",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.collapsed = !_vm.collapsed}}},[_vm._v(_vm._s(_vm.currentLabel))]):_vm._e()])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }